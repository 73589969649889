var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-pane active"},[_c('div',{staticClass:"responsive-table"},[(_vm.loading)?_c('div',{staticClass:"loading min-h-300"},[_c('LoadingAnim')],1):_c('table',{staticClass:"table table-borderless"},[_c('thead',{},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$tc('app.name', 1)))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('app.created_at')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('app.sent_at')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Status")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$tc('app.message', 2)))])])]),_c('tbody',_vm._l((_vm.campaigns),function(campaign){return _c('tr',{key:campaign.id,attrs:{"to":`history/${campaign.id}`}},[_c('td',{attrs:{"data-label":"Data"}},[_vm._v(" "+_vm._s(campaign.name)+" ")]),_c('td',{attrs:{"data-label":"Tipo"}},[_vm._v(" "+_vm._s(_vm._f("date")(campaign.created_at,'DD/MM/YYYY HH:mm:ss'))+" ")]),_c('td',{attrs:{"data-label":"Remetente"}},[_vm._v(" "+_vm._s(_vm._f("date")(campaign.scheduled_at,'DD/MM/YYYY HH:mm:ss'))+" ")]),_c('td',{attrs:{"data-label":"Status"}},[(campaign.status == 'queued')?_c('span',{staticClass:"badge badge-light"},[_vm._v(_vm._s(_vm.$t('generic-str.scheduled')))]):(campaign.status == 'in_progress')?_c('span',{staticClass:"badge badge-info"},[_vm._v(_vm._s(_vm.$t('generic-str.sending')))]):(campaign.status == 'paused')?_c('span',{staticClass:"badge badge-warning"},[_vm._v(_vm._s(_vm.$t('generic-str.paused-a')))]):(campaign.status == 'sent')?_c('span',{staticClass:"badge badge-success"},[_vm._v(_vm._s(_vm.$tc('generic-str.sent-a')))]):(campaign.status == 'stopped')?_c('span',{staticClass:"badge badge-danger"},[_vm._v(_vm._s(_vm.$t('generic-str.no-funds')))]):_vm._e()]),_c('td',{attrs:{"data-label":"Destinatário"}},[_vm._v(_vm._s(campaign.total_messages))]),_c('td',[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
                `<b>Interface:</b> ${campaign.interface}</br>
                          <b>` +
                _vm.$t('generic-str.content') +
                `:</b> ${campaign.body}</br>` /*Conteúdo*/
              ),expression:"\n                `<b>Interface:</b> ${campaign.interface}</br>\n                          <b>` +\n                $t('generic-str.content') +\n                `:</b> ${campaign.body}</br>` /*Conteúdo*/\n              ",modifiers:{"top":true}}],staticClass:"col"},[_c('i',{staticClass:"icon dripicons-information"})]),(campaign.status != 'queued')?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$tc('generic-str.message', 2)),expression:"$tc('generic-str.message', 2)",modifiers:{"top":true}}],staticClass:"col",attrs:{"role":"button"},on:{"click":function($event){return _vm.$emit('filter', 'history', campaign.id)}}},[_c('i',{staticClass:"icon dripicons-mail"})]):_vm._e(),(campaign.status != 'queued')?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$tc('generic-str.report', 2)),expression:"$tc('generic-str.report', 2)",modifiers:{"top":true}}],staticClass:"col",attrs:{"role":"button"},on:{"click":function($event){return _vm.$emit('filter', 'stat', campaign.id)}}},[_c('i',{staticClass:"icon dripicons-graph-pie"})]):_vm._e(),(campaign.status == 'in_progress')?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('generic-str.pause')),expression:"$t('generic-str.pause')",modifiers:{"top":true}}],staticClass:"col",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.pause(campaign.id)}}},[_c('i',{staticClass:"icon dripicons-media-pause"})]):_vm._e(),(
                campaign.status == 'stopped' || campaign.status == 'paused'
              )?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('generic-str.send')),expression:"$t('generic-str.send')",modifiers:{"top":true}}],staticClass:"col",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.play(campaign.id)}}},[_c('i',{staticClass:"icon dripicons-media-play"})]):_vm._e(),(campaign.status === 'queued')?_c('a',{directives:[{name:"modal",rawName:"v-modal",value:({
                target: 'edit-campaign-modal',
                data: campaign,
              }),expression:"{\n                target: 'edit-campaign-modal',\n                data: campaign,\n              }"},{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('generic-str.edit')),expression:"$t('generic-str.edit')",modifiers:{"top":true}}],staticClass:"col",attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"icon dripicons-pencil"})]):_vm._e(),(campaign.status === 'queued')?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('generic-str.remove')),expression:"$t('generic-str.remove')",modifiers:{"top":true}}],staticClass:"col",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.remove(campaign.id)}}},[_c('i',{staticClass:"icon dripicons-cross"})]):_vm._e()])])}),0)]),_c('div',{staticClass:"row"},[_c('pagination',{attrs:{"lastPage":_vm.pages},on:{"change":_vm.fetch}})],1)]),_c('edit-campaign-modal',{attrs:{"id":"edit-campaign-modal"},on:{"submit":function($event){return _vm.fetch(1)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }